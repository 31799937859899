import Home from "@views/Home"
import { homePageQuery } from "@views/Home/graphql"
import requestStaticProps from "@utils/requestStaticProps"
import { GetStaticProps } from "next"

export default Home

export const getStaticProps: GetStaticProps = async ({ preview = false }) => {
  const { data, error } = (await requestStaticProps(homePageQuery)) || {}

  if (error) {
    console.error(error)
  }

  return {
    props: { data },
  }
}
