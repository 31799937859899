import React from "react"
import Layout from "@components/Layout/hoc/withDatoData"
import Helmet from "@components/Helmet"
import DatoContent from "@dato/DatoContent"
import { renderMetaTags } from "react-datocms"

const Page: any = ({ data }) => {
  return (
    <Layout>
      <Helmet
        title="TMI Products"
        meta={renderMetaTags(data.homePage?._seoMetaTags)}
      />
      {data?.homePage?.content && (
        <DatoContent content={data.homePage.content} />
      )}
    </Layout>
  )
}

export default Page
